import { ButtonBase, ButtonProps, styled, SxProps, Box, Stack } from "@mui/material";
import { FC } from "react";

type SecondaryButtonProps = {
    children?: any;
    sx?: SxProps,
    shadow?: any
};

const SecondaryButton: FC<SecondaryButtonProps & ButtonProps> = (props) => {
    const { children, sx } = props
    return <CustomButton {...props} sx={{ ...sx }}>
        {children}
    </CustomButton>
};

export default SecondaryButton;

const CustomButton = styled(ButtonBase)(({ theme }) => ({
    minWidth: 100,
    minHeight: 45,
    background: 'none',
    borderRadius: '0.5rem',
    position: 'relative',
    zIndex: 10,
    '&:before': {
        content: '""',
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: 'inherit',
        zIndex: -1,
        background: 'linear-gradient(15deg,#BC0FE0 ,#3A1F84 25%, #321C55 35%,#000000 70%)',
    },
    '&:after': {
        content: '""',
        position: "absolute",
        width: 'calc(100% + 2px)',
        height: 'calc(100% + 2px)',
        top: -1,
        left: -1,
        borderRadius: 'inherit',
        zIndex: -2,
        background: 'linear-gradient(15deg,#FA00FF,rgba(255,255,255,0.9) 30%, rgba(255,255,255,0.8) 80%,#4475F3)',
    }
}));
