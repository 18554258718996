import { Box, Stack, Typography } from "@mui/material";
import SecondaryButton from "components/button/SecondaryButton";
import CapitalBalanceChart from "components/charts/CapitalBalanceChart";
import DepositBox from "components/DepositBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import GradientText from "components/GradientText";
import { motion } from "framer-motion";

export default function DepositCapitalProviderPage() {
  return (
    <Stack
      sx={{
        "& section": {
          position: "relative",
          scrollSnapAlign: "center",
        },
      }}
    >
      <Stack sx={{ marginTop: { xs: "150px", xmd: "240px" } }}>
        <FlexBetween
          sx={{
            gap: {
              xs: "48px",
              xmd: "20px",
            },
            width: "100%",
            padding: {
              xxl: "0px 200px",
              sm: "0px 80px",
              xs: "0px 16px",
            },
            flexDirection: {
              xs: "column",
              xmd: "row",
            },
          }}
        >
          <Stack flex={1} maxWidth="564px" gap="48px" display="grid">
            <GradientText
              text="Deposit to Capital Provider Earn DOUBLE Yield + Points"
              background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
              fontSize={{ xs: 24, xmd: 40 }}
              fontWeight={600}
              textAlign={"center"}
            />

            <SecondaryButton
              sx={{
                borderRadius: "9px",
                padding: { xs: "17px 24px", xmd: "17px 34px" },
              }}
            >
              <FlexBetween width={"100%"}>
                <FlexBox
                  sx={{
                    gap: { xs: "6px", xmd: "16px" },
                    alignItems: "center",
                    flexDirection: {
                      xs: "column",
                      xmd: "row",
                    },
                  }}
                >
                  <Typography
                    fontSize={{ xs: 14, xdm: 20 }}
                    color="#BFBCCD"
                    fontWeight={600}
                  >
                    Total Yield
                  </Typography>
                  <FlexBox gap={2} alignItems={"center"}>
                    <img
                      src="/static/coins/USDC.svg"
                      style={{ width: 30, height: 30 }}
                      alt="usdc"
                    />
                    <Typography fontSize={{ xs: 14, xdm: 20 }} fontWeight={600}>
                      USDC
                    </Typography>
                  </FlexBox>
                </FlexBox>
                <Typography
                  fontSize={{ xs: 14, xdm: 24 }}
                  ml={1}
                  fontWeight={600}
                >
                  12.29%
                </Typography>
              </FlexBetween>
            </SecondaryButton>
            <FlexBetween gap={"24px"} width={"100%"}>
              <FlexBetween
                sx={{
                  flex: 1,
                  padding: "16px 24px",
                  border: "1px solid #fff",
                  borderRadius: "9px",
                }}
              >
                <FlexBox gap="12px" alignItems="center">
                  <Stack
                    sx={{
                      width: { xs: "26px", xmd: "48px" },
                      height: { xs: "26px", xmd: "48px" },
                    }}
                  >
                    <img
                      src="/static/coins/AAVE.svg"
                      style={{ width: "100%", height: "100%" }}
                      alt="aave"
                    />
                  </Stack>
                  <Typography
                    fontSize={{ xs: 12, xmd: 20 }}
                    fontWeight={600}
                    color="#BFBCCD"
                  >
                    AAVE
                  </Typography>
                </FlexBox>
                <Typography fontSize={{ xs: 12, xmd: 24 }} fontWeight={600}>
                  6%
                </Typography>
              </FlexBetween>
              <FlexBetween
                sx={{
                  flex: 1,
                  padding: "16px 24px",
                  border: "1px solid #fff",
                  borderRadius: "9px",
                }}
              >
                <FlexBox gap="12px" alignItems="center">
                  <Stack
                    sx={{
                      width: { xs: "26px", xmd: "48px" },
                      height: { xs: "26px", xmd: "48px" },
                    }}
                  >
                    <img
                      src="/static/coins/FLAEX.svg"
                      style={{ width: "100%", height: "100%" }}
                      alt="flaex"
                    />
                  </Stack>
                  <Typography
                    fontSize={{ xs: 12, xmd: 20 }}
                    fontWeight={600}
                    color="#BFBCCD"
                  >
                    FLAEX
                  </Typography>
                </FlexBox>
                <Typography fontSize={{ xs: 12, xmd: 24 }} fontWeight={600}>
                  8.25%
                </Typography>
              </FlexBetween>
            </FlexBetween>
            <motion.div
              style={{
                position: "absolute",
                width: "55%",
                height: "65%",
                top: 0,
                left: 0,
                zIndex: -1,
              }}
              initial={{
                filter: "blur(200px)",
              }}
              animate={{
                filter: `blur(15px)`,
              }}
              transition={{
                duration: 1,
              }}
            >
              <motion.img
                src="/logo.svg"
                style={{
                  width: "60%",
                  height: "60%",
                  position: "absolute",
                  top: "20%",
                  left: "20%",
                  objectFit: "contain",
                  zIndex: -1,
                  opacity: 0.15,
                  filter: "blur(15px)",
                  transform: "scale(1)",
                }}
                animate={{
                  rotate: [0, -360],
                }}
                transition={{
                  duration: 20,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                }}
              />
            </motion.div>
          </Stack>
          <DepositBox />
        </FlexBetween>
      </Stack>
      <Stack
        sx={{
          width: { xs: "60%", xmd: "716px" },
          margin: { xs: "63px auto", xmd: "96px auto" },
        }}
      >
        <img src="/static/separator.svg" alt="separator" />
      </Stack>
      <Stack sx={{ marginBottom: { xs: "100px", xmd: "240px" } }}>
        <FlexBetween
          sx={{
            gap: {
              xs: "11px",
              xmd: "38px",
            },
            width: "100%",
            padding: {
              xxl: "0px 200px",
              sm: "0px 80px",
              xs: "0px",
            },
            flexDirection: {
              xs: "column",
              xlg: "row",
            },
          }}
        >
          <CapitalBalanceChart />
          <Box
            sx={{
              width: {
                xs: "100%",
                xlg: "auto",
              },
              display: "grid",
              gap: { xs: "6px", xmd: "24px" },
              gridTemplateColumns: {
                xs: "1fr 1fr 1fr",
                xlg: "1fr",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "auto", xlg: "356px" },
                border: "1px solid white",
                padding: { xs: "24px 11px", xmd: "38px 24px" },
                background:
                  "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={{ xs: 12, xmd: 16 }}
                fontWeight={600}
                color="#BFBCCD"
                marginBottom="20px"
                textTransform={{ xs: "none", xmd: "uppercase" }}
              >
                Total Flaex Capital
              </Typography>
              <Typography fontSize={{ xs: 14, xmd: 36 }} fontWeight={600}>
                $100,000
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "auto", xlg: "356px" },
                border: "1px solid white",
                padding: { xs: "24px 11px", xmd: "38px 24px" },
                background:
                  "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={{ xs: 12, xmd: 16 }}
                fontWeight={600}
                color="#BFBCCD"
                marginBottom="20px"
                textTransform={{ xs: "none", xmd: "uppercase" }}
              >
                open interest
              </Typography>
              <Typography fontSize={{ xs: 14, xmd: 36 }} fontWeight={600}>
                $100,000
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "auto", xlg: "356px" },
                border: "1px solid white",
                padding: { xs: "24px 11px", xmd: "38px 24px" },
                background:
                  "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={{ xs: 12, xmd: 16 }}
                fontWeight={600}
                color="#BFBCCD"
                marginBottom="20px"
                textTransform={{ xs: "none", xmd: "uppercase" }}
              >
                healthy factor
              </Typography>
              <FlexBox gap="16px" alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: { xs: 24, xmd: 35 },
                    height: { xs: 24, xmd: 35 },
                    borderRadius: "100px",
                    background: "#68FF42",
                  }}
                ></Box>
                <Typography fontSize={{ xs: 14, xmd: 36 }} fontWeight={600}>
                  1.5
                </Typography>
              </FlexBox>
            </Box>
          </Box>
        </FlexBetween>
      </Stack>
      <Stack
        sx={{
          background: 'url("/static/layouts/background-grid.svg")',
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></Stack>
    </Stack>
  );
}
