import { Stack, Typography, TypographyProps } from "@mui/material";
import FlexBetween from "./flexbox/FlexBetween";

type LineTextProps = {
  text?: string | React.ReactNode;
} & TypographyProps;

export default function LineText({ text, ...props }: LineTextProps) {
  return (
    <FlexBetween gap="18px">
      <Stack flex={1} height="2px" sx={{ background: "#fff" }}></Stack>
      <Typography
        fontSize={{
          xs: 12,
          xmd: 16,
        }}
        fontStyle="italic"
        fontWeight={400}
        {...props}
      >
        {text}
      </Typography>
      <Stack flex={1} height="2px" sx={{ background: "#fff" }}></Stack>
    </FlexBetween>
  );
}
