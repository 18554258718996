import {
  alpha,
  AppBar,
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChainSelector from "components/Account/ChainSelector";
import ConnectAccount from "components/Account/ConnectAccount";
import Iconify from "components/Iconify";
import { SettingsContext } from "contexts/settingsContext";
import SearchIcon from "icons/SearchIcon";
import ThemeIcon from "icons/ThemeIcon";
import React, { FC, useContext, useState } from "react";
import { themeSettingsTypes } from "theme";
import LanguagePopover from "./popovers/LanguagePopover";
import ProfilePopover from "./popovers/ProfilePopover";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import AppSelect from "components/AppSelect";
import FlexBetween from "components/flexbox/FlexBetween";
import {
  BorderAllRounded,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import FlexRowAlign from "components/flexbox/FlexRowAlign";

// ------------------------------------------------
type DashboardHeaderProps = {
  setShowSideBar?: () => void;
  setShowMobileSideBar?: () => void;
};
// ------------------------------------------------

// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingLeft: "4rem",
  paddingRight: "2rem",
  height: 70,
  background: "none",
  color: theme.palette.text.primary,
  justifyContent: "center",
  marginTop: 1,
  top: "1rem",
  [theme.breakpoints.down('md')]: {
    top: '-2px',
    background: theme.palette.background.default,
  }
}));

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  background: "none",
  width: '100vw',
  height: 50,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '4rem',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  }
}));

const DashboardHeader: FC<DashboardHeaderProps> = (props) => {
  const { setShowMobileSideBar } = props;

  const [openSearchBar, setSearchBar] = useState(false);
  const { settings, saveSettings } = useContext(SettingsContext);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));

  const handleChangeDirection = (value: "ltr" | "rtl") => {
    saveSettings({ ...settings, direction: value } as themeSettingsTypes);
  };
  const handleChangeTheme = (value: "light" | "dark") => {
    saveSettings({ ...settings, theme: value } as themeSettingsTypes);
  };

  const routes = [
    { to: "/trade", label: "TRADE" },
    { to: "/airdrop", label: "AIRDROP" },
    { to: "/leader-board", label: "LEADERBOARD" },
    {
      to: "",
      label: "DEPOSIT",
      items: [
        {
          to: "/deposit/trade-funding",
          label: "Trading Fund",
          icon: (
            <img
              src="/static/svg/list-style.svg"
              style={{ width: 50, height: 50 }}
            />
          ),
        },
        {
          to: "/deposit/capital-provider",
          label: "Capital Provider",
          icon: (
            <img
              src="/static/svg/list-style.svg"
              style={{ width: 50, height: 50 }}
            />
          ),
        },
      ],
    },
    { to: "/docs", label: "DOCS" },
  ];

  return (
    <DashboardHeaderRoot
      position="fixed"
      sx={{ paddingLeft: { xs: "1rem", lg: "4rem" } }}
    >
      <StyledToolBar>
        <Link to="/">
          <Stack sx={{ display: { xs: "block", md: "none" } }}>
            <img src="/logo.svg" style={{ height: 25 }} alt="logo" />
          </Stack>
          <Stack sx={{ display: { xs: "none", md: "block" } }}>
            <img src="/logo-full.png" style={{ height: 25 }} alt="logo" />
          </Stack>
        </Link>
        {/* <FlexBetween gap={3} ml={5} display={{ xs: "none", lg: "flex" }}>
          {routes.map((item) => (
            <MenuList key={item.to} data={item} />
          ))}
        </FlexBetween> */}
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;

const MenuList = ({ data }: { data: any }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      {data?.items ? (
        <Button
          onClick={handleClick}
          endIcon={
            <Iconify icon={"solar:alt-arrow-down-line-duotone"} size={20} />
          }
          disableRipple
          sx={{
            fontSize: "1rem",
            color: theme.palette.text.secondary,
            fontWeight: 400,
            "&:hover": {
              color: theme.palette.text.primary,
            },
          }}
        >
          {data?.label}
        </Button>
      ) : (
        <Link to={data?.to}>
          <Typography
            sx={{
              fontSize: "1rem",
              color: theme.palette.text.secondary,
              fontWeight: 400,
              transition: "all 0.1s ease-in-out",
              "&:hover": {
                color: theme.palette.text.primary,
              },
            }}
          >
            {data?.label}
          </Typography>
        </Link>
      )}
      {data?.items && (
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          keepMounted={false}
        >
          {data?.items?.map((item: any) => (
            <MenuItem key={item.to} onClick={handleClose} disableRipple>
              <Link to={item.to}>
                <FlexRowAlign
                  gap={1}
                  justifyContent={"flex-start"}
                  sx={{
                    fontSize: "1rem",
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    transition: "all 0.1s ease-in-out",
                    "&:hover": {
                      color: theme.palette.text.primary,
                    },
                  }}
                >
                  {item?.icon}
                  {item?.label}
                </FlexRowAlign>
              </Link>
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px",
      borderRadius: "4px",
      background: "#000",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
