import { ButtonProps, styled, Button } from "@mui/material";

type GradientButtonProps = {
  children?: any;
} & ButtonProps;

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #8001FE 0%, #FDA9E5 100%)",
  color: "#000",
  fontSize: 20,
  fontStyle: "italic",
  fontWeight: 600,
  padding: "10px 40px",
  textTransform: "none", // Prevent uppercase transformation
  border: "none",
  borderRadius: "0",
  clipPath:
    "polygon( 2.949% 100%,94.766% 100%,100% 15.179%,97.051% 0%,5.234% 0%,0% 84.821%,2.949% 100% )", // Hexagonal shape with two cut corners
  "&:hover": {
    background: "linear-gradient(90deg, #8001FE 0%, #FDA9E5 100%)",
    opacity: 0.9,
  },
}));

export default function GradientButton({
  children,
  ...props
}: GradientButtonProps) {
  return <CustomButton {...props}>{children}</CustomButton>;
}
