import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowRight(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      fill="none"
      viewBox="0 0 36 37"
      {...props}
    >
      <g clipPath="url(#clip0_368_458)">
        <path
          fill="#6E698D"
          d="M20.39 8.84L30 18.5l-9.61 9.66a1.001 1.001 0 01-1.67-1.09 1 1 0 01.28-.32l7.19-7.25H7.06a1 1 0 110-2h19.13L19 10.25a1 1 0 111.42-1.41h-.03z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_368_458">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="matrix(0 -1 -1 0 36 36.5)"
          ></path>
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
