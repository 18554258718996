import { Theme, alpha } from "@mui/material";

export const shapes = (theme: Theme): any => {
    const output = {
        borderRadius: 8,
        buttonRadius: 8
    }


    return output;
};
