import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useInView, motion } from "framer-motion";

const LoadingInitScreen = () => {
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const innerHeight = window.innerHeight;
  const theme = useTheme()
  const ref = useRef(null)
  const isInView = useInView(ref, {
    once: false,
    margin: `${-innerHeight / 2}px`,
  });

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 20); // 3000ms / 100 = 30ms per increment

    return () => clearInterval(progressInterval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setFadeOut(true);
      }, 1000);
    }
  }, [progress]);

  return (
    <Box
      ref={ref}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.background.default,
        opacity: fadeOut ? 0 : 1,
        transition: "opacity 1s ease-out",
        pointerEvents: fadeOut ? "none" : "auto",
        zIndex: 0,
      }}
      display={{ xs: "grid", sm: "flex" }}
    >
      <Typography fontSize={20} marginBottom="20px" letterSpacing={"4px"}>
        {progress}%
      </Typography>
      <Typography
        fontSize={24}
        textTransform={"uppercase"}
        letterSpacing={"8px"}
        textAlign={"center"}
        padding={"0px 20px"}
      >
        {progress < 100
          ? `On our road . . .`
          : "Initializing the road map . . ."}
      </Typography>

      <motion.div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
        }}
        initial={{
          filter: "blur(200px)",
        }}
        animate={{
          filter: `blur(${isInView ? 15 : 200}px)`,
        }}
        transition={{
          duration: 1,
        }}
      >
        <motion.img
          src="logo.svg"
          style={{
            width: "60vw",
            height: "60vh",
            position: "absolute",
            top: "calc(50% - 30vh)",
            left: "calc(50% - 30vw)",
            objectFit: "contain",
            zIndex: -1,
            opacity: 0.15,
            filter: "blur(15px)",
            transform: "scale(2)",
          }}
          animate={{
            rotate: [0, -360],
          }}
          transition={{
            duration: 20,
            ease: "linear",
            times: [0, 1],
            repeat: Infinity,
          }}
        />
      </motion.div>
      <Stack
        sx={{
          background: 'url("static/layouts/background-grid.svg")',
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></Stack>
    </Box>
  );
};

export default LoadingInitScreen;
