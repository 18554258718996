import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState, ReactNode } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

// --------------------------------------------
type DashboardLayoutProps = {
  children?: ReactNode;
};
// --------------------------------------------

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(true);

  const customStyle: CSSProperties = {
    width: `100vw`,
  };

  return (
    <Fragment>
      <DashboardHeader />
      <LayoutBodyWrapper sx={customStyle}>
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
