import { Stack, Typography } from "@mui/material";
import GradientButton from "components/button/GradientButton";
import DepositBox from "components/DepositBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import GradientText from "components/GradientText";
import { motion } from "framer-motion";
import { FC } from "react";

const DepositTradingFundPage: FC = () => {
  return (
    <Stack
      sx={{
        "& section": {
          minHeight: "100vh",
          position: "relative",
          scrollSnapAlign: "center",
        },
      }}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"100vh"}
        padding={{ xs: "120px 0px", lg: 0 }}
      >
        <FlexBetween
          sx={{
            gap: {
              xs: "48px",
              xmd: "20px",
            },
            width: "100%",
            padding: {
              xxl: "0px 200px",
              sm: "0px 80px",
              xs: "0px 16px",
            },
            flexDirection: {
              xs: "column",
              xmd: "row",
            },
          }}
        >
          <Stack
            flex={1}
            maxWidth="564px"
            minWidth="420px"
            gap="30px"
            display="grid"
          >
            <GradientText
              text="Deposit to Trading Fund Earn Yield + Flaex Points"
              background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
              fontSize={{
                xs: 26,
                xmd: 32,
                lg: 44,
              }}
              fontWeight={600}
              textAlign={{ xs: "center", xmd: "left" }}
              padding={{ xs: "0px 50px", sm: "unset" }}
            />
            <Stack
              border="1px solid #4C4663"
              padding="16px 24px"
              borderRadius="9px"
              width={{ xs: "311px", sm: "auto" }}
              margin={{ xs: "0px auto", sm: "unset" }}
            >
              <FlexBetween>
                <FlexBox alignItems="center" gap="12px">
                  <img
                    src="/static/coins/USDC.svg"
                    style={{ width: 48, height: 48 }}
                    alt="usdc"
                  />
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    USDC
                  </Typography>
                </FlexBox>
                <Typography fontSize="24px" fontWeight={600}>
                  6%
                </Typography>
              </FlexBetween>
            </Stack>
            <Typography
              fontSize={{
                xs: 12,
                xmd: 18,
                lg: 24,
              }}
              textAlign={{
                xs: "center",
                xmd: "left",
              }}
            >
              You will earn more points over time based on the amount you
              deposit.
            </Typography>
            <motion.div
              style={{
                position: "absolute",
                width: "55%",
                height: "100%",
                top: 0,
                left: 0,
                zIndex: -1,
              }}
              initial={{
                filter: "blur(200px)",
              }}
              animate={{
                filter: `blur(15px)`,
              }}
              transition={{
                duration: 1,
              }}
            >
              <motion.img
                src="/logo.svg"
                style={{
                  width: "60%",
                  height: "60%",
                  position: "absolute",
                  top: "20%",
                  left: "20%",
                  objectFit: "contain",
                  zIndex: -1,
                  opacity: 0.15,
                  filter: "blur(15px)",
                  transform: "scale(1)",
                }}
                animate={{
                  rotate: [0, -360],
                }}
                transition={{
                  duration: 20,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                }}
              />
            </motion.div>
            <GradientButton
                sx={{
                  fontSize: {
                    xs: 12,
                    xmd: 20,
                  },
                }}
              >
                Enter App
              </GradientButton>
          </Stack>
          
        </FlexBetween>
      </Stack>
      <Stack
        sx={{
          background: 'url("/static/layouts/background-grid.svg")',
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></Stack>
    </Stack>
  );
};

export default DepositTradingFundPage;
