import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Iconify({ icon, sx, onClick, size, ...other }: { icon: any, onClick?: any, sx?: any, size?: number }) {
  // return null
  return <Box component={Icon} icon={icon} sx={{ ...sx, width: size, height: size }} {...other} />;
}
