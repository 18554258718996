import {
  Box,
  Button,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowRight from "icons/ArrowRight";
import PetroPumpIcon from "icons/PetroPumpIcon";
import { useState } from "react";
import AppSelect from "./AppSelect";
import GradientButton from "./button/GradientButton";
import WithdrawDialog from "./dialog/WithdrawDialog";
import FlexBetween from "./flexbox/FlexBetween";
import FlexBox from "./flexbox/FlexBox";
import GradientBorder from "./GradientBorder";
import LineText from "./LineText";
import { Span } from "./Typography";
import TradingFundIcon from "icons/TradingFundIcon";
import AppTextField from "./input-fields/AppTextField";
import GradientText from "./GradientText";

type DepositBoxProps = {
  to?: string;
};

export default function DepositBox({ to = "Trading Fund" }: DepositBoxProps) {
  const [tabValue, setTabValue] = useState("deposit");
  const [open, setOpen] = useState(true);

  const handleTabChange = (value: string) => () => {
    if (value === "withdraw") {
      setOpen(true);
    }
    setTabValue(value);
  };

  const data = [];

  return (
    <GradientBorder sx={{ width: { xs: "100%", xmd: "600px" } }}>
      <FlexBox>
        {TAB_LIST.map((item, index) => (
          <Box
            key={index}
            onClick={handleTabChange(item.value)}
            sx={{
              background: tabValue === item.value ? "transparent" : "#2D2D2D",
              color: tabValue === item.value ? "#fff" : "#6D6D6D",
              flex: 1,
              width: "min-content",
              textAlign: "center",
              cursor: "pointer",
              fontSize: {
                xs: 14,
                lg: 20,
              },
              fontStyle: "italic",
              fontWeight: 700,
              padding: {
                xs: "14px",
                lg: "14px 30px",
              },
            }}
          >
            {item.label}
          </Box>
        ))}
      </FlexBox>
      <Box padding="18px" position="relative">
        {(tabValue === "deposit" || tabValue === "withdraw") && (
          <>
            <Stack display="grid" gap="18px">
              <LineText text="YOU CAN DEPOSIT" />
              <FlexBox gap="5px">
                <Typography fontSize={{ xs: 18, xms: 20 }} fontWeight={600}>
                  5,000.01
                </Typography>
                <img
                  src="/static/coins/USDC.svg"
                  style={{ width: 26, height: 26 }}
                  alt="usdc"
                />
              </FlexBox>
              <Box
                sx={{
                  display: "grid",
                  gap: "18px",
                  padding: "12px",
                  border: "1px solid #8C8C8C",
                }}
              >
                <FlexBetween
                  gap="12px"
                  flexDirection={{ xs: "column", xmd: "row" }}
                >
                  <AppSelect
                    formControlSx={{
                      height: "100%",
                      flex: 1,
                      width: { xs: "100%", xmd: "auto" },
                    }}
                    sx={{
                      height: "100%",
                      borderWidth: 0,
                      borderRadius: 0,
                      background: "#141414",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                    options={[
                      { value: "ETH", label: "ETH" },
                      { value: "USDC", label: "USDC" },
                      { value: "BTC", label: "BTC" },
                    ]}
                    defaultValue={"ETH"}
                    renderItem={(value: any) => (
                      <FlexBetween>
                        <Typography>{value.label}</Typography>
                      </FlexBetween>
                    )}
                  />
                  <ArrowRight
                    sx={{
                      rotate: { xs: "90deg", xmd: "0deg" },
                    }}
                  />
                  <FlexBox
                    sx={{
                      width: { xs: "100%", xmd: "auto" },
                      flex: 1,
                      padding: "13.5px 12px",
                      gap: "12px",
                      alignItems: "center",
                      background: "#141414",
                    }}
                  >
                    <TradingFundIcon
                      sx={{
                        flexShrink: 0,
                        width: { xs: 24, lg: 36 },
                        height: { xs: 24, lg: 36 },
                      }}
                    />
                    <Stack>
                      <Typography
                        fontSize={{
                          xs: 14,
                          lg: 15,
                        }}
                        fontWeight={400}
                        color="#989898"
                      >
                        To
                      </Typography>
                      <Typography
                        fontSize={{
                          xs: "15px",
                          lg: "20px",
                        }}
                        fontWeight={600}
                        color="#667AFF"
                      >
                        {to}
                      </Typography>
                    </Stack>
                  </FlexBox>
                </FlexBetween>
                <FlexBetween
                  sx={{
                    gap: "16px",
                    padding: "12px",
                    border: "1px solid #fff",
                  }}
                >
                  <AppTextField
                    placeholder="0.00"
                    sx={{
                      width: "100%",
                      border: 0,
                      "& .MuiOutlinedInput-input": {
                        fontSize: {
                          xs: 24,
                          xmd: 32,
                        },
                        fontWeight: 700,
                        fontStyle: "italic",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                  />
                  <Stack sx={{ flexShrink: 0 }}>
                    <Typography
                      fontSize={{
                        xs: 12,
                        xmd: 15,
                      }}
                      fontWeight={400}
                      color="#636363"
                      marginBottom="6px"
                    >
                      Balance: 0 USDC
                    </Typography>
                    <Button
                      sx={{
                        padding: "4px 8px",
                        background: "#424242",
                        width: "fit-content",
                        marginLeft: "auto",
                        ":hover": {
                          background: "#424242",
                        },
                      }}
                    >
                      <GradientText
                        text="MAX"
                        background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
                        fontSize={{
                          xs: 12,
                          xmd: 15,
                        }}
                        fontWeight={600}
                      />
                    </Button>
                  </Stack>
                </FlexBetween>
              </Box>
              <LineText text="You will receive 1.00 USDC + Yield + Points" />
              <GradientButton
                sx={{
                  fontSize: {
                    xs: 12,
                    xmd: 20,
                  },
                }}
              >
                Enter App
              </GradientButton>
            </Stack>
            {tabValue === "withdraw" && (
              <WithdrawDialog open={open} setOpen={setOpen} maxWidth="348px">
                <Box
                  padding="24px 20px"
                  borderRadius="10px"
                  sx={{ background: "#292929" }}
                >
                  <Typography
                    fontSize={{
                      xs: 12,
                      xmd: 16,
                    }}
                    fontWeight={500}
                    marginBottom="18px"
                  >
                    You will no longer earn points or yield if you withdraw
                  </Typography>
                  <GradientButton
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: "#fff",
                      marginBottom: "5px",
                    }}
                  >
                    BACK TO AIRDROP DASHBOARD
                  </GradientButton>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: 14,
                        xmd: 15,
                      },
                      fontWeight: 600,
                      fontStyle: "italic",
                      color: "#A3A3A3",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Continue anyway
                  </Typography>
                </Box>
              </WithdrawDialog>
            )}
          </>
        )}

        {tabValue === "swap" && (
          <Stack display="grid" gap="18px">
            <LineText text="FROM UNISWAP" />
            <FlexBox gap="5px">
              <Typography
                fontSize={{
                  xs: 14,
                  xmd: 16,
                }}
                fontWeight={600}
              >
                Your balance: 5,000.01
              </Typography>
              <img
                src="/static/coins/USDC.svg"
                style={{ width: 26, height: 26 }}
                alt="usdc"
              />
            </FlexBox>
            <Box
              display="grid"
              gap="18px"
              padding="12px"
              border="1px solid #8C8C8C"
            >
              <FlexBetween padding="16px 12px" border="1px solid #fff">
                <Stack>
                  <Typography
                    fontSize={{ xs: 14, xmd: 15 }}
                    fontWeight={400}
                    color="#636363"
                  >
                    Sell
                  </Typography>
                  <Typography fontSize={{ xs: 24, xmd: 32 }} fontWeight={700}>
                    1
                  </Typography>
                  <Typography
                    fontSize={{ xs: 14, xmd: 15 }}
                    fontWeight={400}
                    color="#636363"
                  >
                    $2786.2
                  </Typography>
                </Stack>
                <AppSelect
                  sx={{
                    borderWidth: 0,
                    borderRadius: 0,
                    background: "#141414",
                  }}
                  options={[
                    { value: "ETH", label: "ETH" },
                    { value: "USDC", label: "USDC" },
                    { value: "BTC", label: "BTC" },
                  ]}
                  defaultValue={"ETH"}
                  renderItem={(value: any) => (
                    <FlexBetween>
                      <Typography>{value.label}</Typography>
                    </FlexBetween>
                  )}
                />
              </FlexBetween>
              <FlexBetween padding="16px 12px" border="1px solid #fff">
                <Stack>
                  <Typography
                    fontSize={{ xs: 14, xmd: 15 }}
                    fontWeight={400}
                    color="#636363"
                  >
                    Buy
                  </Typography>
                  <Typography fontSize={{ xs: 24, xmd: 32 }} fontWeight={700}>
                    2749.0
                  </Typography>
                  <Typography
                    fontSize={{ xs: 14, xmd: 15 }}
                    fontWeight={400}
                    color="#636363"
                  >
                    $2786.2
                  </Typography>
                </Stack>
                <AppSelect
                  sx={{
                    borderWidth: 0,
                    borderRadius: 0,
                    background: "#141414",
                  }}
                  options={[
                    { value: "ETH", label: "ETH" },
                    { value: "USDC", label: "USDC" },
                    { value: "BTC", label: "BTC" },
                  ]}
                  defaultValue={"ETH"}
                  renderItem={(value: any) => (
                    <FlexBetween>
                      <Typography>{value.label}</Typography>
                    </FlexBetween>
                  )}
                />
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={{ xs: 12, xmd: 13 }} fontWeight={700}>
                  1 USDC = 0.00036 ETH <Span color="#6E698D">($1.00)</Span>
                </Typography>
                <FlexBox gap="6px" alignItems="center">
                  <PetroPumpIcon />
                  <Typography
                    fontSize={{ xs: 12, xmd: 13 }}
                    fontWeight={700}
                    color="#BFBCCD"
                  >
                    $0.45
                  </Typography>
                </FlexBox>
              </FlexBetween>
            </Box>
            <LineText
              text={
                <Typography
                  fontSize={{ xs: 12, xmd: 16 }}
                  fontWeight={400}
                  color="#FDFDFD"
                  textAlign="center"
                >
                  You will receive 1.00 ETH.{" "}
                  <Span color="#FF002E">No rewards or yield.</Span> <br />7 days
                  withdrawal peiod
                </Typography>
              }
            />
            <GradientButton
              sx={{ textTransform: "uppercase", fontSize: { xs: 14, xmd: 20 } }}
            >
              Switch to ARbitrum mainnet
            </GradientButton>
          </Stack>
        )}

        {tabValue === "history" && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: { xs: 12, xmd: 20 },
                      fontWeight: 700,
                      fontStyle: "italic",
                      color: "#6D6D6D",
                      textAlign: "center",
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: 12, xmd: 20 },
                      fontWeight: 700,
                      fontStyle: "italic",
                      color: "#6D6D6D",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: 12, xmd: 20 },
                      fontWeight: 700,
                      fontStyle: "italic",
                      color: "#6D6D6D",
                      textAlign: "center",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: 12, xmd: 20 },
                      fontWeight: 700,
                      fontStyle: "italic",
                      color: "#6D6D6D",
                      textAlign: "center",
                    }}
                  >
                    Transaction
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 &&
                  data.map((row) => (
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#6D6D6D",
                          fontSize: { xs: 12, xmd: 16 },
                          textAlign: "center",
                        }}
                      >
                        0.00
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#6D6D6D",
                          fontSize: { xs: 12, xmd: 16 },
                          textAlign: "center",
                        }}
                      >
                        Deposit
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#6D6D6D",
                          fontSize: { xs: 12, xmd: 16 },
                          textAlign: "center",
                        }}
                      >
                        Success
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#6D6D6D",
                          fontSize: { xs: 12, xmd: 16 },
                          textAlign: "center",
                        }}
                      >
                        0x1234567890
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data.length === 0 && (
              <FlexBox
                sx={{
                  height: "350px",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                  fontWeight: 700,
                  color: "#6D6D6D",
                  fontStyle: "italic",
                }}
              >
                No data
              </FlexBox>
            )}
          </>
        )}
      </Box>
    </GradientBorder>
  );
}

const TAB_LIST = [
  {
    label: "Deposit",
    value: "deposit",
  },
  {
    label: "Withdraw",
    value: "withdraw",
  },
  {
    label: "Swap",
    value: "swap",
  },
  {
    label: "History",
    value: "history",
  },
];
