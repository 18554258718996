import * as React from "react";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

type SelectProps = {
  multiple?: boolean;
  placeholder?: string;
  renderItem?: any;
  options?: any[];
  onChange?: any;
  sx?: SxProps;
  formControlSx?: SxProps;
  defaultValue?: any;
};

export default function AppSelect(props: SelectProps) {
  const theme = useTheme();
  const {
    multiple,
    placeholder,
    renderItem,
    options,
    onChange,
    sx,
    formControlSx,
    defaultValue,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    const {
      target: { value },
    } = event;
    const found = options?.find((item) => item.value === value);
    onChange(found);
    setSelectedValue(typeof value === "string" ? value.split(",") : value);
  };

  React.useEffect(() => {
    setSelectedValue(
      defaultValue?.value ? [defaultValue?.value] : [defaultValue]
    );
  }, [defaultValue]);

  return (
    <FormControl sx={{ ...formControlSx }}>
      <Select
        sx={{ ...sx }}
        multiple={multiple}
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          const found = options?.find((item) => item.value === selected[0]);
          if (!found) {
            return <em>{placeholder ? placeholder : "Select item"}</em>;
          }
          return renderItem(found);
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            {renderItem(option) || option?.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
