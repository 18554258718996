import { Paragraph } from "components/Typography";
import { Box, BoxProps, styled } from "@mui/material";

type GradientTextProps = {
  text: string;
  background?: string;
  ellipsis?: boolean;
} & BoxProps;

export default function GradientText({
  text,
  background,
  ...props
}: GradientTextProps) {
  return (
    <Paragraph
      sx={{
        background,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
      {...props}
    >
      {text}
    </Paragraph>
  );
}
