import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import DepositTradingFundPage from "pages/deposit-trading-fund";
import DepositCapitalProviderPage from "pages/deposit-capital-provider";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// Landing page
const HomePage = Loadable(lazy(() => import("./pages/home-page")));
const AirdropPage = Loadable(lazy(() => import("./pages/airdrop-page")));

// 404/Error page
const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const { settings } = useSettings();
  // console.log(settings);
  switch (settings.activeLayout) {
    case "layout3":
      return <LayoutV3 />;
    default:
      return <LayoutV3 />;
  }
};

const routes = () => {
  return [
    ...authRoutes,
    {
      path: "",
      element: <ActiveLayout />,
      children: defaultRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [{ path: "/", element: <Navigate to="/homepage" /> }];

const defaultRoutes = [
  { path: "/homepage", element: <HomePage /> },
  { path: "/deposit/trade-funding", element: <DepositTradingFundPage /> },
  {
    path: "/deposit/capital-provider",
    element: <DepositCapitalProviderPage />,
  },
  { path: "/airdrop", element: <AirdropPage /> },
];

export default routes;
