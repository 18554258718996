import { Stack, StackProps, styled } from "@mui/material";

const StyledStack = styled(Stack)(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

type WithdrawDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
} & StackProps;

export default function WithdrawDialog({
  open,
  setOpen,
  children,
  ...props
}: WithdrawDialogProps) {
  return open ? (
    <StyledStack>
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: -1,
          background: "#000",
          opacity: 0.8,
          backdropFilter: "blur(10px)",
        }}
        onClick={() => setOpen(false)}
      ></Stack>
      <Stack {...props}>{children}</Stack>
    </StyledStack>
  ) : null;
}
