import { Box, Typography } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import GradientBorder from "components/GradientBorder";
import ChevronDown from "icons/ChevronDown";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function CapitalBalanceChart() {
  const [time, setTime] = useState("day");

  const chartOptions = {
    chart: {
      id: "basic-area-chart",
      type: "area",
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
      },
    },
    grid: {
      borderColor: "#6E698D", // Color of the grid lines
      strokeDashArray: 4, // Makes the grid lines dashed
    },
    xaxis: {
      categories: ["JUL 27", "AUG 1", "AUG 6", "AUG 11", "AUG 16", "AUG 21"],
      labels: {
        style: {
          colors: "#6E698D", // Change this to your desired color
        },
      },
    },
    yaxis: {
      stepSize: 50,
      labels: {
        style: {
          colors: "#6E698D", // Change this to your desired color
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#AF93FF"],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        colorStops: [
          {
            offset: 50,
            color: "#6934FF81",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#3F1F9900",
            opacity: 0.4,
          },
        ],
      },
    },
  };

  const chartSeries = [
    {
      name: "Balance",
      data: [240, 260, 220, 240, 250, 170],
    },
  ];

  const timeOpts = [
    {
      label: "1D",
      value: "day",
    },
    {
      label: "1W",
      value: "week",
    },
    {
      label: "1M",
      value: "month",
    },
    {
      label: "1Y",
      value: "year",
    },
  ];

  return (
    <GradientBorder
      sx={{
        flex: 1,
        width: {
          xs: "100%",
          xlg: "auto",
        },
      }}
      borderRadius={"9px"}
    >
      <Box
        sx={{
          padding: { xs: "10px", xmd: "36px" },
        }}
      >
        <FlexBetween
          sx={{
            gap: "18px",
            marginBottom: "60px",
            alignItems: "start",
            flexDirection: {
              xs: "column",
              xmd: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "grid",
              gap: "12px",
            }}
          >
            <Typography
              fontSize={{ xs: 14, xmd: 20 }}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              my capital Balance
            </Typography>
            <Typography fontSize={{ xs: 24, xmd: 40 }} fontWeight={600}>
              $209.189.290
            </Typography>
            <FlexBox gap={"10px"} alignItems={"center"}>
              <ChevronDown sx={{ color: "#EB5757" }} />
              <Typography
                fontSize={{ xs: 12, xmd: 14 }}
                fontWeight={400}
                color={"#EB5757"}
              >
                16% (-$192,389.22)
              </Typography>
            </FlexBox>
          </Box>
          <FlexBox gap={"12px"}>
            {timeOpts.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "50px",
                  borderRadius: "4px",
                  background: time === item.value ? "#BFBCCD" : "#211B2E",
                  color: time === item.value ? "#211B2E" : "#BFBCCD",
                  cursor: "pointer",
                }}
                onClick={() => setTime(item.value)}
              >
                {item.label}
              </Box>
            ))}
          </FlexBox>
        </FlexBetween>
        <Box color={"#000"}>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="area"
            height={350}
          />
        </Box>
      </Box>
    </GradientBorder>
  );
}
