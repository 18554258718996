import { BoxProps, Box } from "@mui/material";

type GradientBorderProps = {
  children: React.ReactNode;
  gradient?: string;
  background?: string;
  borderWidth?: string;
} & BoxProps;

export default function GradientBorder({
  children,
  gradient,
  background = "#000",
  borderWidth = "1px",
  sx,
  ...props
}: GradientBorderProps) {
  return (
    <Box
      sx={{
        background:
          gradient ||
          "linear-gradient(30deg,#FA00FF,#B580FF 15%,#FFFFFF 35%,#FFFFFF 80%,#4475F3)",
        padding: borderWidth,
        borderRadius: props.borderRadius,
        ...sx,
      }}
    >
      <Box sx={{ background, borderRadius: props.borderRadius }} {...props}>
        {children}
      </Box>
    </Box>
  );
}
