import { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import routes from "./routes";
import "./i18n";
import { Buffer } from 'buffer';
import { Web3ReactProvider } from "@web3-react/core";
import connectors from "./connectors";

window.Buffer = Buffer;

const App: FC = () => {
  const router = createBrowserRouter(routes());
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    chainNumber: 1
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
